/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { FaSync, FaSearch } from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";

const TotalPrintArsipIndex = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const navigate = useNavigate();

  const [tglMulai, setTglMulai] = useState("");
  const [tglAkhir, setTglAkhir] = useState("");

  const [idOutlet, setIdOutlet] = useState("");

  const [dataReport, setDataReport] = useState([]);

  const [outlet, setOutlet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const btnLoading = isLoading ? "is-loading" : "";

  useEffect(() => {
    refreshToken();
    allOutlet();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const allOutlet = async () => {
    setIsLoading(true);
    await axiosJWT
      .get(`${API_URL_AUTH}/admin/all-outlet`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        setOutlet(response.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.message);
      });
  };

  const reportTotalPrint = async () => {
    setIsLoading(true);
    await axiosJWT
      .get(
        `https://apipayment1.psisimonline.id/admin/total-print-arsip?outletId=${idOutlet}&date_first=${tglMulai}&date_second=${tglAkhir}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setDataReport(response.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.message);
      });
  };

  const searchData = (e) => {
    e.preventDefault();

    reportTotalPrint();
  };

  return (
    <>
      <nav className="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="is-active">
            <Link href="#" aria-current="page">
              Laporan Total Print
            </Link>
          </li>
        </ul>
      </nav>
      <form onSubmit={searchData} className="mb-5">
        <div className="columns is-desktop">
          <div className="column">
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Tanggal</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="date"
                      className="input is-small"
                      value={tglMulai}
                      onChange={(e) => setTglMulai(e.target.value)}
                      placeholder="0000-00-00"
                    />
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="date"
                      className="input is-small"
                      value={tglAkhir}
                      min={tglMulai}
                      onChange={(e) => setTglAkhir(e.target.value)}
                      placeholder="0000-00-00"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Outlet</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <span className="select is-small is-fullwidth">
                      <select
                        value={idOutlet}
                        onChange={(e) => setIdOutlet(e.target.value)}
                      >
                        <option key={`peru-00`} value="">
                          - Semua Outlet -
                        </option>
                        {outlet.map((outlet, index) => (
                          <option key={`peru-${index}`} value={outlet.id}>
                            {outlet.namaOutlet}
                          </option>
                        ))}
                      </select>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="buttons is-fullwidth is-centered has-background-light pt-2">
          <button
            type="submit"
            className={`button is-info is-small is-rounded ${btnLoading}`}
          >
            <span className="icon">
              <FaSearch />
            </span>
            <span>Filter Data</span>
          </button>

          <a
            href="/laporan-tes"
            className="button is-link is-small is-rounded is-light is-right"
          >
            <span className="icon">
              <FaSync />
            </span>
            <span className="is-hidden-mobile">Refresh</span>
          </a>
        </p>
      </form>

      <div className="table-container">
        <table className="table is-striped is-bordered is-fullwidth is-hoverable is-size-7">
          <thead className="table-header">
            <tr>
              <th>Nama Petugas</th>
              <th>Total Input</th>
              <th>Total Print</th>
            </tr>
          </thead>
          <tbody>
            {dataReport.map((pt, index) => (
              <tr key={index}>
                <td>{pt.namaUser}</td>
                <td>
                  <Link
                    to={`detail?userId=${pt.userId}&outletId=${pt.outletId}&jmlPrint=${pt.totalPrint}&date_first=${tglMulai}&date_second=${tglAkhir}`}
                  >
                    {pt.totalInput}
                  </Link>
                </td>
                <td>{pt.totalPrint}x</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TotalPrintArsipIndex;
