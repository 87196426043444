/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API_URL_AUTH } from "../../config/config";

const DataTotalPrintArsip = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [payment, setPayment] = useState([]);
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  /* eslint-disable-next-line */
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);

  const [msg, setMsg] = useState("");

  useEffect(() => {
    refreshToken();
    getAllDataTotalPrint();
  }, [page, limit]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllDataTotalPrint = async () => {
    setIsLoading(true);
    await axiosJWT
      .get(
        `https://apipayment1.psisimonline.id/admin/detail-print-arsip?date_first=${searchParams.get(
          "date_first"
        )}&date_second=${searchParams.get(
          "date_second"
        )}&userId=${searchParams.get("userId")}&jmlPrint=${searchParams.get(
          "jmlPrint"
        )}&outletId=${searchParams.get(
          "outletId"
        )}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setPayment(response.data.data);
        setPage(response.data.page);
        setPages(response.data.totalPage);
        setRows(response.data.totalRows);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const changePage = ({ selected }) => {
    setPage(selected);
    if (selected === 9) {
      setMsg(
        "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
      );
    } else {
      setMsg("");
    }
  };

  return (
    <>
      {payment ? (
        <>
          <div className="table-container">
            <table className="table is-striped is-bordered is-fullwidth is-hoverable is-size-7">
              <thead className="table-header">
                <tr>
                  <th>No.</th>
                  <th>No. Transaksi</th>
                  <th>No. Tes</th>
                  <th>Pemohon</th>
                  <th>No.HP / Alamat</th>
                  <th>SIM</th>
                  <th>Jen. SIM</th>
                  <th>Jen. Tes</th>
                  <th>Print</th>
                  <th>Jen. Bayar</th>
                  <th>Biaya</th>
                  <th>PPN</th>
                  <th>Service</th>
                  <th>Total Bayar</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {payment &&
                  payment.map((p, index) => (
                    <tr key={p.id}>
                      <td>{page * limit + index + 1}</td>
                      <td>
                        <strong>{p.noTransaksi}</strong>
                        <br />
                        ------
                        <br />
                        {p.namaOutlet}
                        <br />
                        {p.namaUser}
                      </td>
                      <td>
                        {p.nomorTes}
                        <br />
                        ------
                        <br />
                        <strong>{p.jenPemohon}</strong>
                      </td>
                      <td>
                        <strong>{p.namaPemohon}</strong>
                        <br />
                        {p.nik}
                        <br />
                        ------
                        <br />
                        {p.tmpLahir}, {p.tglLahir}
                      </td>
                      <td>
                        <strong>{p.noHp}</strong>
                        <br />
                        ------
                        <br />
                        {p.alamat}
                      </td>
                      <td>{p.kategoriSim}</td>
                      <td>{p.jenSim}</td>
                      <td>{p.jenTes}</td>
                      <td>{p.totalPrint}x</td>
                      <td>{p.jenBayar}</td>
                      <td>{p.totalBiaya}</td>
                      <td>{p.ppn}</td>
                      <td>{p.service}</td>
                      <td>{p.totalBayar}</td>
                      <td>{p.createdAt}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <p className="has-text-centered has-text-danger">{msg}</p>
          <div className="columns">
            <div className="column">
              <span className="is-size-7">
                Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}
              </span>
            </div>
            <div className="column">
              <nav
                className="pagination is-rounded is-small is-right"
                key={rows}
                role="navigation"
                aria-label="pagination"
              >
                <ReactPaginate
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  pageCount={Math.min(10, pages)}
                  onPageChange={changePage}
                  containerClassName={"pagination-list"}
                  pageLinkClassName={"pagination-link"}
                  previousLinkClassName={"pagination-previous"}
                  nextLinkClassName={"pagination-next"}
                  activeLinkClassName={"pagination-link is-current"}
                  disabledLinkClassName={"pagination-link is-disabled"}
                />
              </nav>
            </div>
          </div>
        </>
      ) : (
        <div className="is-fullwidth has-text-centered mt-5">
          <figure className="image is-2by2 is-inline-block">
            <img
              src="https://plan.literasikitaindonesia.com/kasir/no-data-found.png"
              alt="logo cart"
            />
          </figure>
          <h4>Data tidak ditemukan</h4>
        </div>
      )}
    </>
  );
};

export default DataTotalPrintArsip;
