import "bulma/css/bulma.css";
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { API_URL_AUTH } from "../../config/config";

const Modal = ({ show, idP, closeModal }) => {
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const navigate = useNavigate();

  const isActive = show ? "is-active" : "";

  const [payment, setPayment] = useState([]);

  useEffect(() => {
    refreshToken();
    getAllDataTotalPrint();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllDataTotalPrint = async () => {
    await axiosJWT
      .get(`https://apipayment1.psisimonline.id/admin/log-print/${idP}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPayment(response.data.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <>
      <div className={`modal ${isActive}`}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title has-text-danger">DATA LOG PRINT</p>
            <button
              onClick={closeModal}
              className="delete"
              aria-label="close"
            />
          </header>
          <section className="modal-card-body">
            {payment ? (
              <>
                <div className="table-container">
                  <table className="table is-striped is-bordered is-fullwidth is-hoverable is-size-7">
                    <thead className="table-header">
                      <tr>
                        <th>No.</th>
                        <th>Nama Petugas</th>
                        <th>Nama Outlet</th>
                        <th>Status Print</th>
                        <th>Alasan</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payment.map((p, index) => (
                        <tr key={`pay${index}`}>
                          <td>{index + 1}</td>
                          <td>{p.namaUser}</td>
                          <td>{p.namaOutlet}</td>
                          <td>{p.statusPrint}</td>
                          <td>{p.alasan}</td>
                          <td>{p.createdAt}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="is-fullwidth has-text-centered mt-5">
                <figure className="image is-2by2 is-inline-block">
                  <img
                    src="https://plan.literasikitaindonesia.com/kasir/no-data-found.png"
                    alt="logo cart"
                  />
                </figure>
                <h4>Data tidak ditemukan</h4>
              </div>
            )}
          </section>
          <footer className="modal-card-foot">
            <button onClick={closeModal} className="button is-outlined">
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Modal;
