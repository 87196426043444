/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { FaSync, FaSearch } from "react-icons/fa";
import { API_URL_AUTH, API_URL_TES } from "../../config/config";

const PendidikanIndex = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const navigate = useNavigate();

  const [tglFirst, setTglFirst] = useState("");
  const [tglSecond, setTglSecond] = useState("");
  const [katSim, setKatSim] = useState("");
  const [statusBayar, setStatusBayar] = useState("");
  const [dataPemohon, setDataPemohon] = useState([]);
  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const btnLoading = isLoading ? "is-loading" : "";

  useEffect(() => {
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllPemohon = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await axiosJWT
      .get(
        `${API_URL_TES}/admin/laporan-pendidikan?tglFirst=${tglFirst}&tglSecond=${tglSecond}&statusBayar=${statusBayar}&katSim=${katSim}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setDataPemohon(response.data.data);
        setShow(true);

        setIsLoading(false);
      })
      .catch((e) => {
        setShow(false);
      });
  };

  return (
    <>
      <nav className="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="is-active">
            <Link href="#" aria-current="page">
              Laporan Data Pendidikan
            </Link>
          </li>
        </ul>
      </nav>
      <form onSubmit={getAllPemohon} className="mb-5">
        <div className="columns is-desktop">
          <div className="column">
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Tanggal</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="date"
                      className="input is-small"
                      value={tglFirst}
                      onChange={(e) => setTglFirst(e.target.value)}
                      placeholder="0000-00-00"
                    />
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="date"
                      className="input is-small"
                      value={tglSecond}
                      min={tglSecond}
                      onChange={(e) => setTglSecond(e.target.value)}
                      placeholder="0000-00-00"
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field is-horizontal">
                  <div className="field-label is-small">
                    <label className="label">Filter by</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control">
                        <span className="select is-small is-fullwidth">
                          <select
                            value={katSim}
                            onChange={(e) => setKatSim(e.target.value)}
                          >
                            <option value="">- Semua Kategori -</option>
                            <option value="A">SIM A</option>
                            <option value="B">SIM B</option>
                            <option value="C">SIM C</option>
                            <option value="D">SIM D</option>
                          </select>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Status Bayar</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <span className="select is-small is-fullwidth">
                      <select
                        value={statusBayar}
                        onChange={(e) => setStatusBayar(e.target.value)}
                      >
                        <option value="">- Semua Jenis Bayar -</option>
                        <option value="Y">LUNAS</option>
                        <option value="N">BELUM LUNAS</option>
                      </select>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="buttons is-fullwidth is-centered has-background-light pt-2">
          <button
            type="submit"
            className={`button is-info is-small is-rounded ${btnLoading}`}
          >
            <span className="icon">
              <FaSearch />
            </span>
            <span>Filter Data</span>
          </button>

          <a
            href="/laporan-pendidikan"
            className="button is-link is-small is-rounded is-light is-right"
          >
            <span className="icon">
              <FaSync />
            </span>
            <span className="is-hidden-mobile">Refresh</span>
          </a>
        </p>
      </form>

      {show ? (
        <>
          {dataPemohon.length > 0 ? (
            <>
              <div className="table-container">
                <table className="table is-striped is-bordered is-fullwidth is-hoverable is-size-7">
                  <thead className="table-header">
                    <tr>
                      <th>No.</th>
                      <th>Pendidikan</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataPemohon.map((pemohon, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{pemohon._id}</td>
                        <td>{pemohon.total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="is-fullwidth has-text-centered mt-5">
              <figure className="image is-2by2 is-inline-block">
                <img
                  src="https://plan.literasikitaindonesia.com/kasir/no-data-found.png"
                  alt="logo cart"
                />
              </figure>
              <h4>Data tidak ditemukan</h4>
            </div>
          )}
        </>
      ) : (
        <div className="is-fullwidth has-text-centered mt-5">
          <img
            src="https://plan.literasikitaindonesia.com/psisim/please-filter2.png"
            alt="logo cart"
            width={380}
          />
          <h4>Silahkan filter data terlebih dahulu</h4>
        </div>
      )}
    </>
  );
};

export default PendidikanIndex;
